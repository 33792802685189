import {
  filter,
  find,
  flatten,
  get,
  isArray,
  isEmpty,
  join,
  map,
  orderBy,
} from 'lodash';
import PropTypes, { object } from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { FaExternalLinkAlt, FaReply } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import {
  DataNotFound,
  InputSelectField,
  InputText,
  SelectInput,
  SubmitButton,
} from '../../../components/common';
import { appActions } from '../../../config/actions';
import {
  formatMetadata,
  formatProgrammes,
  generalFormatter,
} from '../../../helpers/dataFormatter';
import PreviewChange from './PreviewChange';

function ApplicationForm({
  currentEvent,
  currentTab,
  setCurrentTab,
  navigateUser,
}) {
  const { metadata } = useSelector((state) => state.app);
  const { activeProgramme } = useSelector((state) => state.auth);
  const { submittingApplication, programmes } = useSelector(
    (state) => state.service
  );
  const dispatch = useDispatch();
  const { register, control, reset, watch, handleSubmit, errors } = useForm();
  const [programmeOptions, setProgrammeOptions] = useState([]);
  const [academicYearOptions, setAcademicYearOptions] = useState([]);
  const [studyYearOptions, setStudyYearOptions] = useState([]);
  const [studyTypeOptions, setStudyTypeOptions] = useState([]);
  const [campusOptions, setCampusOptions] = useState([]);
  const [subjectCombOptions, setSubjectCombOptions] = useState([]);
  const [showPreview, setShowPreview] = useState(false);
  const [selectedData, setSelectedData] = useState({});

  const watchNewProgramme = watch('new_programme_id', '');

  useEffect(() => {
    if (isEmpty(metadata)) {
      dispatch(appActions.getMetadata());
    }
  }, []);

  useEffect(() => {
    if (isArray(programmes)) {
      setProgrammeOptions(formatProgrammes(programmes));
    }

    if (metadata) {
      setAcademicYearOptions(
        orderBy(
          filter(
            formatMetadata(metadata, 'ACADEMIC YEARS', 'id', 'desc'),
            (e) => e.label === currentEvent.academic_year
          ),
          ['label'],
          'desc'
        )
      );
    }
  }, [currentEvent, metadata, programmes]);

  useEffect(() => {
    reset(selectedData);
  }, [showPreview]);

  useEffect(() => {
    let programmeStudyYears = [];

    let subjectCombinations = [];

    let programmeTypes = [];

    let campuses = [];

    if (watchNewProgramme && watchNewProgramme.value) {
      const findProgramme = find(
        programmes,
        (programme) => programme.id === watchNewProgramme?.value
      );

      if (findProgramme && findProgramme.programme_study_years) {
        programmeStudyYears = generalFormatter(
          findProgramme.programme_study_years,
          'programme_study_year',
          'context_id'
        );
      }

      if (findProgramme && !isEmpty(findProgramme.programme_types)) {
        programmeTypes = generalFormatter(
          findProgramme.programme_types,
          'programme_type',
          'programme_type_id'
        );
      }

      if (
        findProgramme &&
        !isEmpty(findProgramme.subject_combinations_categories)
      ) {
        const combinationsSubjects = orderBy(
          flatten(
            map(
              findProgramme.subject_combinations_categories,
              'subject_combinations'
            )
          ),
          ['subject_combination_code']
        );
        subjectCombinations = map(combinationsSubjects, (e) => ({
          value: e.subject_combination_id,
          label: `${e.subject_combination_code} - ${join(
            map(e.subjects, 'subject_code'),
            '/'
          )}`,
        }));
      }

      if (findProgramme && !isEmpty(findProgramme.campuses)) {
        campuses = generalFormatter(
          findProgramme.campuses,
          'campus',
          'campus_id'
        );
      }
    }
    setCampusOptions(campuses);
    setStudyTypeOptions(programmeTypes);
    setSubjectCombOptions(subjectCombinations);
    setStudyYearOptions(programmeStudyYears);
  }, [watchNewProgramme]);

  const submitFormHandler = (data) => {
    if (!isEmpty(data) && !submittingApplication) {
      const applicationData = {
        ...data,
        event_id: currentEvent?.id,
      };

      setSelectedData(applicationData);
      setShowPreview(true);
    }
  };

  const handleClick = () => {
    setCurrentTab('service-dashboard');
    navigateUser();
  };

  return (
    <>
      {isEmpty(currentEvent) ? (
        <DataNotFound message="CHANGE OF PROGRAMME HAS NOT BEEN OPENED FOR YOUR PROGRAMME, PLEASE CONTACT YOUR ACADEMIC REGISTRAR FOR HELP">
          <div className="text-center my-3">
            <Button
              size="sm"
              onClick={handleClick}
              className="text-sm font600 text-uppercase ms-1"
              variant="warning"
            >
              <FaReply className="me-1" />
              Back
            </Button>
          </div>
        </DataNotFound>
      ) : (
        <>
          {showPreview && !isEmpty(selectedData) ? (
            <PreviewChange
              data={selectedData}
              setShowPreview={setShowPreview}
              currentTab={currentTab}
            />
          ) : (
            <Form onSubmit={handleSubmit(submitFormHandler)}>
              <div className="mb-3">
                <InputText
                  name="current_programme"
                  label="CURRENT PROGRAMME"
                  inline
                  defaultValue={activeProgramme.programme_title}
                  requiredField
                  readOnly
                />
              </div>
              <InputSelectField
                label="NEW PROGRAMME"
                requiredField
                inline
                placeholder="Select programme"
                selectOptions={programmeOptions}
                name="new_programme_id"
                control={control}
                rules={{
                  required: 'This field is required',
                }}
                error={get(errors, 'new_programme_id.message')}
              />
              <Fragment key={watchNewProgramme?.value}>
                {!isEmpty(subjectCombOptions) && (
                  <InputSelectField
                    name="new_subject_comb_id"
                    label="NEW SUBJECT COMBINATION:"
                    requiredField
                    inline
                    control={control}
                    selectOptions={subjectCombOptions}
                    rules={{
                      required: 'Select Subject Combination',
                    }}
                    error={get(errors, 'new_subject_comb_id.message')}
                  />
                )}
                <SelectInput
                  name="new_study_year_id"
                  label="NEW STUDY YEAR:"
                  requiredField
                  inline
                  selectOptions={studyYearOptions}
                  register={register({
                    required: 'Select Study Level',
                  })}
                  error={get(errors, 'new_study_year_id.message')}
                />
                <SelectInput
                  name="new_programme_type_id"
                  label="NEW STUDY TIME:"
                  requiredField
                  inline
                  selectOptions={studyTypeOptions}
                  register={register({
                    required: 'Select Programme Study Type',
                  })}
                  error={get(errors, 'new_programme_type_id.message')}
                />
                <SelectInput
                  name="new_campus_id"
                  label="NEW CAMPUS:"
                  type="select"
                  requiredField
                  inline
                  selectOptions={campusOptions}
                  register={register({
                    required: 'Select a campus',
                  })}
                  error={get(errors, 'new_campus_id.message')}
                />
                <InputSelectField
                  name="academic_year_id"
                  label="ENTRY ACADEMIC YEAR:"
                  type="select"
                  requiredField
                  inline
                  control={control}
                  selectOptions={academicYearOptions}
                  rules={{
                    required: 'Select Entry Academic Year',
                  }}
                  error={get(errors, 'academic_year_id.message')}
                />
              </Fragment>
              <InputText
                name="reason"
                label="REASON FOR CHANGE:"
                type="textarea"
                inline
                rows={5}
                register={register({
                  required: 'Include Reason for Program Change',
                })}
                error={get(errors, 'reason.message')}
              />
              <SubmitButton
                size="sm"
                text="Preview and Submit"
                iconBefore={<FaExternalLinkAlt className="mx-1" />}
                loadingText="Submitting Application..."
                className="float-end text-uppercase"
                loading={submittingApplication}
              />
            </Form>
          )}
        </>
      )}
    </>
  );
}

ApplicationForm.defaultProps = {
  currentEvent: {},
};

ApplicationForm.propTypes = {
  currentEvent: PropTypes.oneOfType([object]),
  setCurrentTab: PropTypes.func.isRequired,
  currentTab: PropTypes.string.isRequired,
  navigateUser: PropTypes.func.isRequired,
};

export default ApplicationForm;
