import { get, isEmpty, sum } from 'lodash';
import PropTypes, { object } from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  ModalBody,
  ModalFooter,
  Row,
} from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  CustomModal,
  DataNotFound,
  DataSpinner,
  InputText,
  SubmitButton,
} from '../../../components/common';
import { paymentActions, settingActions } from '../../../config/actions';
import darkHeader from '../../../helpers/dataTableCustomStyle';

function AllocateModal({ invoiceData }) {
  const dispatch = useDispatch();

  const { handleSubmit, register, control, errors, watch } = useForm();
  const { transactions, loading, offsettingInvoice } = useSelector(
    (state) => state.myTransaction
  );
  const { authUser } = useSelector((state) => state.auth);
  const showAllocateModal = useSelector(
    (state) => state.setting.showAllocateModal
  );
  const [myTransactions, setMyTransactions] = useState([]);
  const [canOffset, setCanOffset] = useState(false);
  const [amountToAllocate, setAmountToAllocate] = useState(0);
  const watchAmounts = watch('amounts_to_allocate');

  useEffect(() => {
    if (isEmpty(transactions)) {
      dispatch(paymentActions.getMyTransactions());
    } else {
      const studentTransactions = [];
      [...transactions.data, ...transactions.deposits].forEach((transaction) =>
        studentTransactions.push(
          ...transaction.payment_transactions.filter(
            (payment) => payment.unallocated_amount > 0
          )
        )
      );
      setMyTransactions(studentTransactions);
    }
  }, [transactions]);

  useEffect(() => {
    if (watchAmounts) {
      const totalAmount = sum(Object.values(watchAmounts).map(Number));
      setAmountToAllocate(totalAmount);
      if (parseInt(totalAmount, 10) === parseInt(invoiceData?.amount_due, 10))
        setCanOffset(true);
      else {
        setCanOffset(false);
      }
    } else setCanOffset(false);
  }, [watchAmounts]);

  const closeModal = () => {
    dispatch(settingActions.setShowAllocateModal(false));
  };

  const allocateButton = useCallback(
    (row) => (
      <>
        <InputText
          name={`amounts_to_allocate[${row.id}]`}
          defaultValue={0}
          type="number"
          min={0}
          max={row.unallocated_amount}
          register={register({
            min: { value: 0, message: 'Invalid Amount' },
            max: {
              value: row.unallocated_amount,
              message: `Maximum amount should be ${row.unallocated_amount}`,
            },
          })}
          control={control}
          error={get(errors, `amounts_to_allocate[${row.id}].message`)}
        />
        <InputText
          name={`payment_ids[${row.id}]`}
          defaultValue={row.id}
          type="hidden"
          register={register}
        />
      </>
    ),
    []
  );

  const columns = useMemo(
    () => [
      {
        name: 'REFERENCE No.',
        wrap: true,
        width: '150px',
        sortable: true,
        selector(row) {
          return row?.reference_number;
        },
      },
      {
        name: 'INVOICE TYPE',
        width: '150px',
        wrap: true,
        sortable: true,
        selector(row) {
          return row?.transaction_origin;
        },
      },
      {
        name: 'UNALLOCATED',
        sortable: true,
        width: '150px',
        cell(row) {
          return parseInt(row.unallocated_amount, 10).toLocaleString();
        },
      },
      {
        name: 'AMOUNT TO ALLOCATE',
        compact: true,
        width: '150px',
        cell: allocateButton,
      },
      {
        name: 'CURRENCY',
        sortable: true,
        selector(row) {
          return row?.currency;
        },
      },
    ],
    []
  );

  const submitForm = (data) => {
    const paymentTransactions = [];

    if (data.amounts_to_allocate) {
      data.amounts_to_allocate.forEach((amount, index) => {
        if (parseInt(amount, 10) > 0) {
          paymentTransactions.push({
            id: parseInt(data.payment_ids[index], 10),
            amount: parseInt(amount, 10),
          });
        }
      });
    }
    const formData = {
      payment_transactions: paymentTransactions,
      student_id: authUser.id,
      invoice_number: invoiceData?.invoice_number,
    };

    dispatch(paymentActions.offsetInvoice(invoiceData?.id, formData));
  };

  return (
    <CustomModal
      title={`OFFSET INVOICE - ${invoiceData?.invoice_number}`}
      scrollable
      centered
      size="lg"
      show={showAllocateModal}
      onCloseModal={closeModal}
      backdrop="static"
      keyboard={false}
    >
      <Form onSubmit={handleSubmit(submitForm)}>
        <ModalBody>
          <Row className="mb-2 text-uppercase">
            <Col md={6}>
              <InputText
                label="Invoice ID"
                defaultValue={invoiceData?.invoice_number}
                disabled
                inline
              />
            </Col>
            <Col md={6}>
              <InputText
                label="Amount Due"
                defaultValue={`${
                  invoiceData?.fees_elements[0].currency
                } ${parseInt(invoiceData?.amount_due, 10).toLocaleString()}`}
                disabled
                inline
              />
            </Col>
          </Row>
          <Row className="my-4">
            <DataTable
              data={myTransactions}
              columns={columns}
              className="border"
              striped
              dense
              noHeader
              progressPending={loading}
              customStyles={darkHeader}
              progressComponent={<DataSpinner />}
              noDataComponent={
                <DataNotFound message="No Unallocated Payment transactions" />
              }
            />
          </Row>
          <Card.Header className="p-2 text-uppercase border bg-light w-100">
            <span className="text-info me-2 font500">
              Total Amount To Allocate:
              {`  ${amountToAllocate.toLocaleString()}`}
            </span>
            <div className="card-options">
              <span className="ms-1 font500">
                Balance Due:
                <span
                  className={
                    invoiceData.amount_due - amountToAllocate < 0
                      ? 'ms-1 text-danger'
                      : 'ms-1 text-success'
                  }
                >
                  {(
                    parseInt(invoiceData?.amount_due, 10) -
                    parseInt(amountToAllocate, 10)
                  ).toLocaleString()}
                </span>
              </span>
            </div>
          </Card.Header>
        </ModalBody>
        <ModalFooter className="py-2 bg-light">
          <Button
            variant="danger"
            className="text-uppercase fw-500"
            onClick={closeModal}
          >
            Cancel
          </Button>
          <SubmitButton
            type="submit"
            text="OFFSET INVOICE"
            loading={offsettingInvoice}
            loadingText="Offsetting..."
            disabled={!canOffset}
          />
        </ModalFooter>
      </Form>
    </CustomModal>
  );
}

AllocateModal.propTypes = {
  invoiceData: PropTypes.oneOfType([object]).isRequired,
};

export default AllocateModal;
