import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { AlertMessage, InputText, SubmitButton } from '../../components/common';
import { authActions } from '../../config/actions';
import RoutePaths from '../../config/routes/RoutePaths';
import usePrevious from '../Hooks/usePrevious';

function ResetForm({ setCurrentPage, setResetSuccess, resetSuccess }) {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm();
  const { requestingToken, resettingPassword } = useSelector(
    (state) => state.auth
  );
  const {
    resetPasswordError,
    requestTokenError,
    resetPasswordSuccess,
    requestTokenSuccess,
  } = useSelector((state) => state.auth);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const previousState = usePrevious({
    requestTokenError,
    requestTokenSuccess,
    resetPasswordError,
    resetPasswordSuccess,
  });

  useEffect(() => {
    setErrorMessage(null);
    setSuccessMessage(null);
    if (!isEmpty(previousState)) {
      if (
        !isEmpty(requestTokenError) &&
        previousState.requestTokenError !== requestTokenError
      ) {
        setErrorMessage(
          requestTokenError?.error || requestTokenError?.server?.message
        );
      }
      if (
        previousState.requestTokenSuccess !== requestTokenSuccess &&
        requestTokenSuccess?.server
      ) {
        setSuccessMessage(requestTokenSuccess?.server?.message);
        setResetSuccess('student');
      }
    }
  }, [requestTokenError, requestTokenSuccess]);

  useEffect(() => {
    if (!isEmpty(previousState)) {
      if (
        !isEmpty(resetPasswordError) &&
        previousState.resetPasswordError !== resetPasswordError
      ) {
        setErrorMessage(
          resetPasswordError?.error || resetPasswordError.server.message
        );
      }
      if (
        previousState.resetPasswordSuccess !== resetPasswordSuccess &&
        resetPasswordSuccess?.server
      ) {
        setCurrentPage(RoutePaths.login.path);
      }
    }
  }, [resetPasswordError, resetPasswordSuccess]);

  const onSubmit = (data) => {
    setErrorMessage(null);
    if (!isEmpty(data) && resetSuccess !== 'not used') {
      dispatch(authActions.resetPassword(data));
    } else {
      dispatch(authActions.requestToken(data));
    }
  };

  return (
    <Card className="bg-white rounded shadow-sm ">
      <Card.Header className="py-3 d-block fw-bold text-center border-light rounded-top">
        RESET YOUR PASSWORD
      </Card.Header>
      <Card.Body>
        {(errorMessage || successMessage) && (
          <AlertMessage
            type={errorMessage ? 'danger' : 'success'}
            message={errorMessage || successMessage}
            className="p-2"
          />
        )}
        <Form onSubmit={handleSubmit(onSubmit)}>
          <InputText
            name="username"
            label="Enter your Student Number"
            requiredField
            readOnly={resetSuccess === 'student'}
            register={register({
              required: 'Enter your Student Number to recover your account',
              minLength: { value: 7, message: 'Username is too short' },
              maxLength: { value: 15, message: 'Username is too long' },
            })}
            error={get(errors, 'username.message')}
          />

          {resetSuccess !== 'not used' && (
            <>
              <InputText
                name="otp_code"
                label="Reset Token"
                register={register({
                  required:
                    'Enter the One Time Password sent to your email or phone',
                  minLength: { value: 5, message: 'Code is too short' },
                  maxLength: { value: 6, message: 'Code is too long' },
                })}
                error={get(errors, 'otp_code.message')}
              />

              <InputText
                name="new_password"
                label="New Password"
                type="password"
                register={register({
                  required: 'Enter your new password',
                  minLength: {
                    value: 6,
                    message: 'Minimum password length should 6 characters',
                  },
                  maxLength: {
                    value: 16,
                    message: 'Maximum password length should 16 characters',
                  },
                })}
                error={get(errors, 'reset_token.message')}
              />

              <InputText
                name="confirm_password"
                label="Confirm New Password"
                type="password"
                register={register({
                  required: 'Confirm your New Password',
                  minLength: {
                    value: 6,
                    message: 'Minimum password length should 6 characters',
                  },
                  maxLength: {
                    value: 16,
                    message: 'Maximum password length should 16 characters',
                  },
                })}
                error={get(errors, 'confirm_password.message')}
              />
            </>
          )}

          <SubmitButton
            className="text-uppercase text-white text-sm mt-3 w-100 mb-2 fw-normal"
            loading={requestingToken || resettingPassword}
            size="sm"
            loadingText={
              resetSuccess !== 'not used'
                ? 'Resetting Password...'
                : 'Requesting...'
            }
            text={
              resetSuccess !== 'not used' ? 'Reset Password' : 'Request Token'
            }
          />
        </Form>
      </Card.Body>
    </Card>
  );
}

ResetForm.propTypes = {
  setCurrentPage: PropTypes.func.isRequired,
  resetSuccess: PropTypes.string.isRequired,
  setResetSuccess: PropTypes.func.isRequired,
};

export default ResetForm;
