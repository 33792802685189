import { find, flatten, get, isEmpty, map } from 'lodash';
import PropTypes, { object } from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { FaExternalLinkAlt, FaReply } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import {
  DataNotFound,
  InputSelectField,
  InputText,
  SubmitButton,
} from '../../../components/common';
import { appActions } from '../../../config/actions';
import { formatMetadata } from '../../../helpers/dataFormatter';
import usePrevious from '../../Hooks/usePrevious';
import PreviewChange from './PreviewChange';

function EditApplicationForm({
  dataToEdit,
  currentEvent,
  setCurrentTab,
  currentTab,
  setIsEditing,
  navigateUser,
}) {
  const { metadata } = useSelector((state) => state.app);
  const { activeProgramme } = useSelector((state) => state.auth);
  const { submittingApplication, programmes, updatedApplication } = useSelector(
    (state) => state.service
  );
  const dispatch = useDispatch();
  const { register, control, reset, handleSubmit, errors } = useForm();
  const [academicYear, setAcademicYear] = useState({});
  const [currentProgramme, setCurrentProgramme] = useState({});
  const [showPreview, setShowPreview] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const prevState = usePrevious({ updatedApplication });
  const [subjectCOmbs, setSubjectCombs] = useState([]);

  useEffect(() => {
    if (isEmpty(metadata)) {
      dispatch(appActions.getMetadata());
    }
  }, []);

  useEffect(() => {
    if (metadata) {
      const findAcademicYear = find(
        formatMetadata(metadata, 'ACADEMIC YEARS', 'id', 'desc'),
        (e) => e.label === currentEvent.academic_year
      );
      setAcademicYear(findAcademicYear);
    }
  }, [currentEvent, metadata]);

  useEffect(() => {
    setTimeout(() => {
      reset({
        reason: dataToEdit?.reason,
        new_subject_comb_id: subjectCOmbs.find(
          (s) =>
            parseInt(s.value, 10) === parseInt(dataToEdit.newSubjectComb.id, 10)
        ),
      });
    });

    if (
      !isEmpty(updatedApplication) &&
      !isEmpty(prevState) &&
      prevState.updatedApplication !== updatedApplication
    ) {
      setIsEditing(false);
    }
  }, [showPreview, subjectCOmbs, updatedApplication]);

  useEffect(() => {
    const findProgramme = find(
      programmes,
      (programme) =>
        parseInt(programme.id, 10) ===
        parseInt(dataToEdit?.new_programme_id, 10)
    );

    let combinations = [];

    if (findProgramme) {
      combinations = flatten(
        map(
          findProgramme?.subject_combinations_categories,
          'subject_combinations'
        )
      );
    }

    setCurrentProgramme(findProgramme || {});
    setSubjectCombs(
      map(combinations, (comb) => ({
        value: comb.subject_combination_id,
        label: map(comb.subjects, 'subject_code').join('/'),
      }))
    );
  }, [programmes, activeProgramme]);

  const submitFormHandler = (data) => {
    if (currentProgramme) {
      data.new_programme_id = currentProgramme.id;

      const findStudyTime = find(
        currentProgramme.programme_study_years,
        (e) => e.programme_study_year === activeProgramme.current_study_year
      );

      if (findStudyTime) {
        data.new_study_year_id = findStudyTime?.context_id;
      }
    }

    if (!isEmpty(data) && !submittingApplication) {
      const applicationData = {
        ...data,
        event_id: currentEvent?.id,
        new_programme_version_id: activeProgramme?.version_id,
        new_campus_id: activeProgramme?.campus_id,
        academic_year_id: academicYear,
        new_programme_type_id: activeProgramme.programme_type_id,
      };

      setSelectedData(applicationData);
      setShowPreview(true);
    }
  };
  const handleClick = () => {
    setCurrentTab('service-dashboard');
    navigateUser();
  };

  return (
    <>
      {isEmpty(currentEvent) ? (
        <DataNotFound message="THERE IS NO ACTIVE CHANGE OF PROGRAMME EVENT FOR YOUR PROGRAMME, PLEASE CONTACT YOUR ACADEMIC REGISTRAR FOR HELP">
          <div className="text-center my-3">
            <Button
              size="sm"
              onClick={handleClick}
              className="text-sm font600 text-uppercase ms-1"
              variant="warning"
            >
              <FaReply className="me-1" />
              Back
            </Button>
          </div>
        </DataNotFound>
      ) : (
        <>
          {showPreview && !isEmpty(selectedData) ? (
            <PreviewChange
              data={selectedData}
              isEditing
              setIsEditing={setIsEditing}
              dataToEdit={dataToEdit}
              setShowPreview={setShowPreview}
              currentTab={currentTab}
              selectedProgramme={currentProgramme}
            />
          ) : (
            <Form onSubmit={handleSubmit(submitFormHandler)}>
              <InputText
                name="current_programme"
                label="CURRENT PROGRAMME"
                inline
                defaultValue={activeProgramme.programme_title}
                requiredField
                readOnly
              />
              <InputText
                name="new_study_year_id"
                label="STUDY YEAR:"
                inline
                readOnly
                defaultValue={activeProgramme?.current_study_year}
              />
              <InputText
                name="new_campus_id"
                label="CAMPUS:"
                inline
                readOnly
                defaultValue={activeProgramme?.campus || 'N/A'}
              />
              <InputText
                name="academic_year"
                label="ACADEMIC YEAR:"
                inline
                readOnly
                key={academicYear?.value}
                defaultValue={academicYear?.label || 'N/A'}
              />
              <InputText
                name="current_study_time"
                label="CURRENT STUDY TIME:"
                inline
                readOnly
                defaultValue={activeProgramme?.programme_type || 'N/A'}
              />
              <Fragment key={currentProgramme?.value}>
                <InputSelectField
                  name="new_subject_comb_id"
                  label="NEW SUBJECT COMBINATION:"
                  requiredField
                  inline
                  selectOptions={subjectCOmbs}
                  control={control}
                  rules={{
                    required: 'Select Your New Subject Combination',
                  }}
                  error={get(errors, 'new_subject_comb_id.message')}
                />
              </Fragment>
              <InputText
                name="reason"
                label="REASON FOR CHANGE:"
                type="textarea"
                requiredField
                inline
                rows={5}
                register={register({
                  required: 'Include Reason for Program Change',
                })}
                error={get(errors, 'reason.message')}
              />
              <SubmitButton
                size="sm"
                text="Preview and Submit"
                loadingText="Submitting Application..."
                className="float-end text-uppercase"
                iconBefore={<FaExternalLinkAlt className="mx-1" />}
                loading={submittingApplication}
              />
              <SubmitButton
                size="sm"
                text="Go Back"
                className="float-end text-uppercase me-1"
                variant="danger"
                iconBefore={<FaReply className="mx-1" />}
                onClick={() => setIsEditing(false)}
              />
            </Form>
          )}
        </>
      )}
    </>
  );
}

EditApplicationForm.defaultProps = {
  currentEvent: {},
};

EditApplicationForm.propTypes = {
  currentEvent: PropTypes.oneOfType([object]),
  dataToEdit: PropTypes.oneOfType([object]).isRequired,
  setCurrentTab: PropTypes.func.isRequired,
  currentTab: PropTypes.string.isRequired,
  setIsEditing: PropTypes.func.isRequired,
  navigateUser: PropTypes.func.isRequired,
};

export default EditApplicationForm;
