import { isEmpty } from 'lodash';
import PropTypes, { object } from 'prop-types';
import React, { useEffect } from 'react';
import { FaReply, FaSave } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import {
  DataNotFound,
  InputText,
  SubmitButton,
} from '../../../components/common';
import { serviceActions } from '../../../config/actions';

function PreviewChange({
  data,
  setShowPreview,
  isEditing,
  dataToEdit,
  selectedProgramme,
  currentTab,
}) {
  const { activeProgramme } = useSelector((state) => state.auth);
  const { submittingApplication, updatingApplication } = useSelector(
    (state) => state.service
  );
  const dispatch = useDispatch();

  useEffect(() => () => setShowPreview(false), []);

  const submitFormHandler = () => {
    if (!isEmpty(data) && !submittingApplication) {
      dispatch(
        serviceActions[isEditing ? 'updateApplication' : 'submitApplication'](
          { ...data, service: currentTab },
          'change-of-programme',
          activeProgramme?.id,
          dataToEdit?.id
        )
      );
    }
  };

  return (
    <>
      {isEmpty(selectedProgramme) ? (
        <DataNotFound message="No New Programme Selected, Please Go back and Select" />
      ) : (
        <>
          <div className="fs-3 fw600 text-center mb-4 text-uppercase">
            CONFIRM YOUR CHANGE OF SUBJECT COMBINATION DATA AND CLICK{' '}
            {isEditing ? 'Update Application' : 'Submit Application'} BUTTON
          </div>

          <InputText
            label="PROGRAMME"
            name="programme"
            inline
            disabled
            defaultValue={activeProgramme?.programme_title}
          />
          <InputText
            label="PROGRAMME TYPE"
            name="programme_type"
            inline
            disabled
            defaultValue={activeProgramme?.programme_type}
          />
          <InputText
            label="STUDY TIME"
            name="study_time"
            inline
            disabled
            defaultValue={activeProgramme?.current_study_year}
          />
          <InputText
            label="CAMPUS"
            name="campus"
            inline
            disabled
            defaultValue={activeProgramme?.campus}
          />
          <InputText
            label="ACADEMIC YEAR"
            name="academic_year_id"
            inline
            disabled
            defaultValue={data?.academic_year_id?.label}
          />
          <InputText
            label="NEW SUBJECT COMBINATION"
            name="new_subject_comb_id"
            inline
            disabled
            defaultValue={data?.new_subject_comb_id?.label}
          />
          <InputText
            label="REASON FOR CHANGE OF COMBINATION"
            name="reason"
            inline
            disabled
            type="textarea"
            defaultValue={data?.reason}
          />

          <div className="text-center d-block">
            <SubmitButton
              size="sm"
              text={isEditing ? 'Update Application' : 'Submit Application'}
              loadingText={
                isEditing ? 'Updating...' : 'Submitting Application...'
              }
              className="float-end text-uppercase mx-2"
              loading={submittingApplication || updatingApplication}
              onClick={submitFormHandler}
              iconBefore={<FaSave className="me-1" />}
            />
            <SubmitButton
              size="sm"
              text="Edit Application"
              className="float-end text-uppercase"
              variant="danger"
              iconBefore={<FaReply className="me-1" />}
              onClick={() => setShowPreview(false)}
            />
          </div>
        </>
      )}
    </>
  );
}

PreviewChange.defaultProps = {
  data: {},
  dataToEdit: {},
  isEditing: false,
};
PreviewChange.propTypes = {
  setShowPreview: PropTypes.func.isRequired,
  currentTab: PropTypes.string.isRequired,
  isEditing: PropTypes.bool,
  data: PropTypes.oneOfType([object]),
  dataToEdit: PropTypes.oneOfType([object]),
  selectedProgramme: PropTypes.oneOfType([object]).isRequired,
};

export default PreviewChange;
