import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { AlertMessage } from '../../components/common';
import RoutePaths from '../../config/routes/RoutePaths';
import ResetForm from './ResetForm';

function ResetPassword({ setCurrentPage }) {
  const [resetSuccess, setResetSuccess] = useState('not used');
  return (
    <>
      <AlertMessage
        type="info"
        message="To reset your default or forgotten password, Enter your STUDENT NUMBER and a password reset token will be sent to your email and phone."
        className="text-md text-uppercase text-center p-3 font500 rounded shadow-sm"
      />
      <ResetForm
        resetSuccess={resetSuccess}
        setResetSuccess={setResetSuccess}
        setCurrentPage={setCurrentPage}
      />
      <div className="mt-3 mb-0 font600 text-center text-sm">
        Have an account?
        <Button
          variant="link"
          className="text-sm"
          onClick={() => setCurrentPage(RoutePaths.login.path)}
        >
          Sign in Here
        </Button>
      </div>
      <div className="mt-0 mb-2 font600 text-center text-sm">
        Have an Admin Token?
        <Button
          variant="link"
          className="text-sm"
          onClick={() => setResetSuccess('admin')}
        >
          Change Password
        </Button>
      </div>
    </>
  );
}

ResetPassword.propTypes = {
  setCurrentPage: PropTypes.func.isRequired,
};

export default ResetPassword;
