import { isEmpty, map, orderBy, reduce, sum, sumBy } from 'lodash';
import moment from 'moment';
import PropTypes, { object } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { FaPrint } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { InputText } from '../../components/common';
import { paymentActions, settingActions } from '../../config/actions';

import { whichInstitution } from '../../helpers/dataFormatter';
import CourseUnitsTable from './CourseUnitsTable';

function SingleRegistrationHistory({
  registrationHistory,
  setSelectedHistory,
}) {
  const { invoices } = useSelector((state) => state.myInvoice);
  const { institutionStructure } = useSelector((state) => state.app);
  const { accountBalance, currentStudentProgramme } = useSelector(
    (state) => state.auth
  );
  const { gettingRegistrationHistory } = useSelector(
    (state) => state.registration
  );
  const { currentSemester } = useSelector((state) => state.app);
  const [showPermit, setShowPermit] = useState(false);
  const [permitErrors, setPermitErrors] = useState([]);
  const isMubs = whichInstitution('MUBS', institutionStructure);

  const dispatch = useDispatch();

  const getInvoices = () => {
    dispatch(
      paymentActions.getMyInvoices({
        student_programme_id: currentStudentProgramme?.student_programme_id,
      })
    );
  };

  useEffect(() => {
    if (isEmpty(invoices)) getInvoices();
  }, []);

  useEffect(() => {
    const errors = [];

    // const enrollment = currentSemester?.is_enrolled;
    // const registration = currentSemester?.is_registered;

    const balanceDue = reduce(
      invoices,
      (totalInvoiceAmount, invoice) => {
        const tuitionDue = sumBy(invoice?.tuition_invoices, 'amount_due');
        const functionalDue = sumBy(
          invoice?.functional_fees_invoices,
          'amount_due'
        );
        const otherFeesDue = sumBy(invoice?.other_fees_invoices, 'amount_due');
        const manualsDue = sumBy(invoice?.manual_invoices, 'amount_due');

        return (
          totalInvoiceAmount +
          sum([tuitionDue, functionalDue, otherFeesDue, manualsDue])
        );
      },
      0
    );

    // remove: enrollment && registration checks
    if (balanceDue === 0) {
      setShowPermit(true);
      setPermitErrors([]);
    } else if (
      isMubs &&
      registrationHistory.registration_type === 'PROVISIONAL REGISTRATION'
    ) {
      setShowPermit(true);
      setPermitErrors([]);
    } else {
      if (balanceDue > 0) {
        errors.push('You have outstanding fees balance!');
      }
      // if (!enrollment) {
      //   errors.push('Please Enroll before downloading permit');
      // }
      // if (!registration) {
      //   errors.push('Please Register before downloading permit');
      // }
      setShowPermit(false);
      setPermitErrors(errors);
    }
  }, [invoices, accountBalance, currentSemester]);

  const downloadHandler = (cardType) => {
    if (cardType === 'examPermit' && showPermit && !isEmpty(permitErrors)) {
      toast.error(
        <>
          <ol>
            {map(permitErrors, (error) => (
              <li key={error}>{error}</li>
            ))}
          </ol>
        </>,
        {
          autoDismiss: true,
          appearance: 'warning',
          autoDismissTimeout: 6000,
        }
      );
    } else {
      dispatch(settingActions.setShowModal(true));
      dispatch(settingActions.setRegistrationCardType(cardType));

      let courseUnits = [];

      if (registrationHistory?.course_units) {
        courseUnits = registrationHistory?.course_units;

        setSelectedHistory({
          ...registrationHistory,
          courseUnits,
        });
      }
    }
  };

  return (
    <>
      <Row>
        <Col md={4}>
          <InputText
            label="REGISTRATION TYPE"
            defaultValue={
              registrationHistory?.registrationType?.metadata_value ||
              registrationHistory?.registration_type
            }
            disabled
          />
        </Col>
        <Col md={4}>
          <InputText
            label="REGISTERED BY"
            defaultValue={
              registrationHistory?.registered_by === 'STUDENT'
                ? 'SELF'
                : registrationHistory?.registered_by
            }
            disabled
          />
        </Col>
        <Col md={4}>
          <InputText
            label="DATE OF REG."
            defaultValue={moment(registrationHistory?.created_at).format(
              'ddd, MMM Do YYYY, h:mm:ss a'
            )}
            disabled
          />
        </Col>
      </Row>

      <Card className="mt-2">
        <Card.Header className="py-2 text-info font600 text-xs">
          COURSE/MODULES REGISTERED
          {registrationHistory?.is_active &&
            !isEmpty(registrationHistory?.course_units) === true && (
              <div className="card-options">
                <Button
                  size="sm"
                  variant="info"
                  className="text-sm fw-bold me-2"
                  disabled={gettingRegistrationHistory}
                  onClick={() => downloadHandler('proofOfRegistration')}
                >
                  <FaPrint className="me-2" />
                  PRINT PROOF OF REGISTRATION
                </Button>

                {showPermit && (
                  <Button
                    size="sm"
                    variant="info"
                    className="text-sm fw-bold"
                    disabled={gettingRegistrationHistory}
                    onClick={() => downloadHandler('examPermit')}
                  >
                    <FaPrint className="me-2" />
                    PRINT EXAM PERMIT
                  </Button>
                )}
              </div>
            )}
        </Card.Header>
        <Card.Body className="p-0">
          <CourseUnitsTable
            courseUnits={orderBy(registrationHistory?.course_units, [
              'course_unit_code',
            ])}
            keyField="course_unit_id"
          />
        </Card.Body>
      </Card>
    </>
  );
}

SingleRegistrationHistory.defaultProps = {
  registrationHistory: {},
};

SingleRegistrationHistory.propTypes = {
  registrationHistory: PropTypes.oneOfType([object]),
  setSelectedHistory: PropTypes.func.isRequired,
};

export default SingleRegistrationHistory;
