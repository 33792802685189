import { isEmpty, sumBy } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { FaExternalLinkAlt, FaFileInvoiceDollar } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import {
  AntDTable,
  CustomModal,
  DataNotFound,
  DataSpinner,
  ReloadButton,
  SubmitButton,
} from '../../../components/common';
import { paymentActions, settingActions } from '../../../config/actions';
import PaymentReceiptModal from '../MyTransactions/PaymentReceiptModal';
import AllocateModal from './AllocateModal';
import GraduationInvoiceDetails from './GraduationInvoiceDetails';
import GraduationPaymentReference from './GraduationPaymentReference';
import GraduationReferenceModal from './GraduationReferenceModal';
import { formatName } from '../../../helpers/dataFormatter';

function GraduationInvoices() {
  const dispatch = useDispatch();
  const { authUser, currentStudentProgramme } = useSelector(
    (state) => state.auth
  );
  const { showModal, showPRNModal, showAllocateModal } = useSelector(
    (state) => state.setting
  );
  const { graduationInvoices, gettingGraduationInvoices } = useSelector(
    (state) => state.myInvoice
  );
  const { generatingInvoice } = useSelector((state) => state.myTransaction);
  const [invoiceGrandTotalAmount, setInvoiceGrandTotalAmount] = useState(0);
  const [invoiceGrandAmountPaid, seInvoiceGrandAmountPaid] = useState(0);
  const [invoiceGrandAmountDue, seInvoiceGrandAmountDue] = useState(0);
  const [invoiceData, setInvoiceData] = useState({});
  const [currentReceipt, setCurrentReceipt] = useState({});
  const [showInvoiceDetails, setShowInvoiceDetails] = useState(false);
  const [showInvoiceReceiptModal, setShowInvoiceReceiptModal] = useState(false);

  const getInvoices = () => {
    dispatch(
      paymentActions.getMyGraduationInvoices(currentStudentProgramme?.id)
    );
  };

  useEffect(() => {
    if (isEmpty(graduationInvoices)) getInvoices();
  }, []);

  useEffect(() => {
    if (!isEmpty(graduationInvoices)) {
      setInvoiceGrandTotalAmount(sumBy(graduationInvoices, 'invoice_amount'));
      seInvoiceGrandAmountDue(sumBy(graduationInvoices, 'amount_due'));
      seInvoiceGrandAmountPaid(sumBy(graduationInvoices, 'amount_paid'));
    }
  }, [graduationInvoices]);

  const openPRNModal = (data) => {
    dispatch(settingActions.setShowPRNModal(true));
    setInvoiceData(data);
  };

  const openOffsetModal = (data) => {
    dispatch(settingActions.setShowAllocateModal(true));
    setInvoiceData(data);
  };

  const openElementsModal = (data) => {
    setShowInvoiceDetails(true);
    setInvoiceData(data);
  };

  const openInvoiceReceipt = (data) => {
    const receipt = {
      full_name: formatName(authUser),
      currency: data.fees_elements[0].currency,
      amount: data.amount_paid,
      student_number: currentStudentProgramme.student_number,
      email: authUser.email,
      phone_number: authUser.phone,
      invoice_number: data.invoice_number,
      amount_billed: data.invoice_amount,
      elements: data.fees_elements,
      usage: 'GRADUATION FEES',
    };
    setCurrentReceipt(receipt);
    setShowInvoiceReceiptModal(true);
  };

  const actions = useCallback(
    (row) => (
      <>
        {row.amount_due > 0 ? (
          <>
            <Button
              variant="outline-primary"
              className="me-2"
              disabled={row.amount_due < 1}
              onClick={() => openPRNModal(row)}
            >
              Generate PRN
            </Button>
            <Button
              variant="outline-danger"
              disabled={row.amount_due < 1}
              onClick={() => openOffsetModal(row)}
            >
              Offset
            </Button>
          </>
        ) : (
          <>
            <Button
              variant="outline-dark"
              onClick={() => openInvoiceReceipt(row)}
            >
              <FaExternalLinkAlt className="text-sm me-2" />
              View Receipt
            </Button>
          </>
        )}
      </>
    ),

    []
  );

  const viewElementsButton = useCallback(
    (row) => (
      <>
        <Button
          variant="link"
          className="me-2 text-info"
          onClick={() => openElementsModal(row)}
        >
          View Details
        </Button>
      </>
    ),

    []
  );

  const columns = useMemo(
    () => [
      {
        title: 'INVOICE NUMBER',
        dataIndex: 'invoice_number',
        width: 130,
      },
      {
        title: 'INVOICE TYPE',
        dataIndex: 'invoice_type',
        width: 130,
      },
      {
        title: 'AMOUNT BILLED',
        width: 120,
        align: 'center',
        dataIndex: 'invoice_amount',
        render: (invoiceAmount) => parseInt(invoiceAmount, 10).toLocaleString(),
      },
      {
        title: 'AMOUNT PAID',
        width: 120,
        align: 'center',
        dataIndex: 'amount_paid',
        wrap: true,
        render: (amountPaid) => parseInt(amountPaid, 10).toLocaleString(),
      },
      {
        title: 'AMOUNT DUE',
        width: 120,
        align: 'center',
        dataIndex: 'amount_due',
        wrap: true,
        render: (amountDue) => parseInt(amountDue, 10).toLocaleString(),
      },
      {
        title: 'CURR',
        width: 70,
        render: (row) => row?.fees_elements[0]?.currency || '---',
      },
      {
        title: 'BILLED ELEMENTS',
        align: 'left',
        width: 200,
        render: viewElementsButton,
      },
      {
        title: 'Actions',
        width: 180,
        fixed: 'right',
        render: actions,
      },
    ],
    []
  );

  const generateGraduationInvoice = () => {
    dispatch(
      paymentActions.generateGraduationInvoice(currentStudentProgramme?.id)
    );
  };

  return (
    <>
      {gettingGraduationInvoices ? (
        <DataSpinner />
      ) : (
        <>
          {isEmpty(graduationInvoices) ? (
            <>
              <Card.Header className="py-3 px-3 text-danger text-sm font500">
                NO GRADUATION INVOICES FOUND
                <div className="card-options">
                  <SubmitButton
                    loading={generatingInvoice}
                    onClick={generateGraduationInvoice}
                    text="Generate Invoice"
                    loadingText="GENERATING..."
                    iconBefore={<FaFileInvoiceDollar className="me-2" />}
                    className="me-2 bg-warning font600 text-uppercase border-0"
                    disabled={
                      currentStudentProgramme?.on_provisional_list !== true
                    }
                  />
                </div>
              </Card.Header>
            </>
          ) : (
            <>
              <Row className="text-center text-muted">
                <Col md={3} className="mb-2">
                  <Card>
                    <Card.Body>
                      <Card.Text className="text-xs font600 mb-2">
                        TOTAL INVOICE AMOUNT
                      </Card.Text>
                      <span className="text-info font600 mx-1">
                        {`${invoiceGrandTotalAmount.toLocaleString()} UGX`}
                      </span>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={3} className="mb-2">
                  <Card>
                    <Card.Body>
                      <Card.Text className="text-xs font600 mb-2">
                        TOTAL INVOICE AMOUNT PAID
                      </Card.Text>
                      <span className="text-success font600 mx-1">
                        {`${invoiceGrandAmountPaid.toLocaleString()} UGX`}
                      </span>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={3} className="mb-2">
                  <Card>
                    <Card.Body>
                      <Card.Text className="text-xs font600 mb-2">
                        TOTAL INVOICE AMOUNT DUE
                      </Card.Text>
                      <span className="text-danger font600 mx-1">
                        {`${invoiceGrandAmountDue.toLocaleString()} UGX`}
                      </span>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={3} className="mb-2">
                  <Card>
                    <Card.Body>
                      <Card.Text className="text-xs font600 mb-2">
                        PERCENTAGE COMPLETION
                      </Card.Text>
                      <span
                        className={`mx-1 font600 ${
                          (invoiceGrandAmountPaid / invoiceGrandTotalAmount) *
                            100 >
                          70
                            ? 'text-success'
                            : 'text-danger'
                        }`}
                      >
                        {`${
                          invoiceGrandTotalAmount !== 0
                            ? (
                                (parseInt(invoiceGrandAmountPaid, 10) /
                                  parseInt(invoiceGrandTotalAmount, 10)) *
                                100
                              ).toFixed(2)
                            : 0
                        } %`}
                      </span>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <div className="border mt-2">
                <Card.Header className="py-2  text-secondary text-sm font600">
                  GRADUATION INVOICES
                  <div className="card-options">
                    <ReloadButton
                      loading={gettingGraduationInvoices}
                      onClick={() => getInvoices()}
                    />
                  </div>
                </Card.Header>
                {gettingGraduationInvoices ? (
                  <DataSpinner />
                ) : (
                  <>
                    {isEmpty(graduationInvoices) ? (
                      <DataNotFound message="No Invoices Found" />
                    ) : (
                      <AntDTable
                        data={graduationInvoices}
                        columns={columns}
                        rowKey="invoice_number"
                        bordered
                        xScroll="100%"
                        pagination={false}
                      />
                    )}
                  </>
                )}
              </div>
              {showInvoiceDetails && (
                <CustomModal
                  show={showInvoiceDetails}
                  title={`INVOICE DETAILS ( ${invoiceData.invoice_number} )`}
                  size="xl"
                  onCloseModal={() => setShowInvoiceDetails(false)}
                >
                  <GraduationInvoiceDetails invoiceDetails={invoiceData} />
                </CustomModal>
              )}
              {showPRNModal && (
                <GraduationPaymentReference invoiceData={invoiceData} />
              )}
              {showAllocateModal && <AllocateModal invoiceData={invoiceData} />}
              {showModal && <GraduationReferenceModal />}
              {showInvoiceReceiptModal && (
                <PaymentReceiptModal
                  setShowModal={setShowInvoiceReceiptModal}
                  selectedReceipt={currentReceipt}
                  showModal={showInvoiceReceiptModal}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );
}

export default GraduationInvoices;
