import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  DataNotFound,
  DataSpinner,
  ReloadButton,
} from '../../../components/common';
import { serviceActions } from '../../../config/actions';
import StudentCardDetails from './StudentCardDetails';
import UploadedCardImage from './UploadedCardImage';

function IdentificationCard() {
  const dispatch = useDispatch();
  const { currentStudentProgramme } = useSelector((state) => state.auth);
  const { studentIdTracker, gettingStudentIdTracker } = useSelector(
    (state) => state.service
  );

  const getIdTrackerDetails = () =>
    dispatch(serviceActions.getStudentIdTracker(currentStudentProgramme?.id));

  useEffect(() => {
    if (isEmpty(studentIdTracker)) getIdTrackerDetails();
  }, []);

  return (
    <>
      <Card>
        <Card.Header className="py-2 px-3">
          <span className="font500 text-uppercase text-primary">
            MY IDENTIFICATION CARD
          </span>
          {/* <div className="card-options">
            <SubmitButton
              variant="danger"
              type="button"
              text="GET DATA"
              className="me-2"
              loading={gettingIdCardData}
              loadingText="Loading..."
              onClick={() =>
                dispatch(
                  serviceActions.getStudentIdDetails(
                    currentStudentProgramme?.id
                  )
                )
              }
            /> */}
          <ReloadButton
            loading={gettingStudentIdTracker}
            onClick={() => getIdTrackerDetails()}
          />
          {/* </div> */}
        </Card.Header>
        <Card.Body>
          {gettingStudentIdTracker && isEmpty(studentIdTracker) ? (
            <DataSpinner />
          ) : (
            <>
              {isEmpty(studentIdTracker) ? (
                <DataNotFound message="NO ID CARD RECORD FOUND" />
              ) : (
                <Row>
                  <Col md={5}>
                    <StudentCardDetails />
                  </Col>
                  <Col md={7}>
                    <UploadedCardImage />
                  </Col>
                </Row>
              )}
            </>
          )}
        </Card.Body>
      </Card>
    </>
  );
}

export default IdentificationCard;
