import { isEmpty, isFunction } from 'lodash';
import PropTypes, { any } from 'prop-types';
import React, { useEffect } from 'react';
import { Button, ButtonGroup, Modal, ModalBody } from 'react-bootstrap';
import { FaExclamationCircle } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { settingActions } from '../../config/actions';
import SubmitButton from './SubmitButton';

function DeletePopUp({
  handleConfirm,
  deleteData,
  deleting,
  itemName,
  showDelete,
  closeDelete,
  deleteError,
}) {
  const dispatch = useDispatch();
  const { showDeleteModal } = useSelector((state) => state.setting);

  const handleClose = () => {
    if (!isFunction(closeDelete)) {
      dispatch(settingActions.showDeleteModal(false));
    } else {
      closeDelete();
    }
  };

  useEffect(() => () => handleClose(), []);

  const onClickConfirm = () => handleConfirm(deleteData);
  return (
    <Modal
      size="md"
      show={showDelete !== null ? showDelete : showDeleteModal}
      onHide={handleClose}
      centered
      backdrop="static"
      keyboard={false}
      dialogClassName="rounded"
      contentClassName="rounded"
    >
      <ModalBody className="text-center p-4">
        <div>
          {deleting ? (
            <div className="text-muted fs-6 fw-bolder my-4">
              Deleting in progress....
            </div>
          ) : (
            <span className="text-danger text-sm font600 text-uppercase">
              {!isEmpty(deleteError) ? (
                <div className="text-danger fs-6 fw-bold my-4">
                  {deleteError}
                </div>
              ) : (
                <div className="font600 d-block">
                  <div>
                    <FaExclamationCircle
                      size="4rem"
                      className="mb-3 text-center"
                    />
                  </div>
                  <Modal.Title className="fs-5 text-muted fw-bold">{`YOU ARE ABOUT TO DELETE THIS ${itemName}`}</Modal.Title>
                  <div className="text-danger fs-6 fw-bolder my-4">
                    Are you sure you want to proceed?
                  </div>
                </div>
              )}
            </span>
          )}
        </div>
        <ButtonGroup size="lg">
          <Button
            onClick={handleClose}
            variant="dark"
            className="fw-bold text-uppercase me-3"
          >
            Cancel
          </Button>
          <SubmitButton
            loading={deleting}
            loadingText="Deleting..."
            className="text-sm font600 text-white"
            onClick={onClickConfirm}
            text="YES, DELETE"
            variant="danger"
          />
        </ButtonGroup>
      </ModalBody>
    </Modal>
  );
}

DeletePopUp.defaultProps = {
  deleting: false,
  deleteData: null,
  deleteError: null,
  showDelete: null,
  closeDelete: null,
};
DeletePopUp.propTypes = {
  handleConfirm: PropTypes.func.isRequired,
  closeDelete: PropTypes.func,
  deleteData: PropTypes.oneOfType([any]),
  deleting: PropTypes.bool,
  showDelete: PropTypes.bool,
  itemName: PropTypes.string.isRequired,
  deleteError: PropTypes.string,
};

export default DeletePopUp;
