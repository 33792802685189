import { filter, isArray, isEmpty, map, sum, sumBy } from 'lodash';
import PropTypes, { object } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Accordion, Card, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { SubmitButton } from '../../components/common';
import { paymentReferenceActions } from '../../config/actions';
import ReferenceModal from '../PaymentReferences/ReferenceModal';
import InvoiceCard from './InvoiceCard';

function GeneratePaymentReference({ enrollment }) {
  const dispatch = useDispatch();
  const { register, errors, handleSubmit, watch } = useForm({
    defaultValues: {
      tuition_amount: map(enrollment?.tuitionInvoice, 'invoice_amount'),
      tuition_fees_amount_ids: map(enrollment?.tuitionInvoice, 'id'),
      functional_amount: [enrollment?.functionalInvoice?.amount_due],
      functional_fees_amount_ids: [enrollment?.functionalInvoice?.id],
      other_fees: map(enrollment?.otherFeesInvoice, 'amount_due'),
      other_fees_amount_ids: map(enrollment?.otherFeesInvoice, 'id'),
    },
  });
  const [activeKey, setActiveKey] = useState(1);
  const { generatingPaymentReference } = useSelector(
    (state) => state.paymentReference
  );

  const watchValues = watch();

  const totalAmount =
    parseInt(enrollment?.functionalInvoice?.invoice_amount, 10) +
    sumBy(enrollment?.tuitionInvoice?.invoice_amount, 'invoice_amount') +
    sumBy(enrollment?.otherFeesInvoice, 'invoice_amount');

  const [newTotalAmount, setNewTotalAmount] = useState(totalAmount);

  useEffect(() => {
    if (!isEmpty(watchValues)) {
      let newTotal = 0;
      if (watchValues.tuition_fees) {
        newTotal += parseInt(watchValues.tuition_fees, 10);
      }

      if (watchValues.functional_fees) {
        newTotal += parseInt(watchValues.functional_fees, 10);
      }

      if (watchValues.other_fees) {
        let otherFeesAmounts = [];
        if (isArray(watchValues.other_fees)) {
          otherFeesAmounts = map(watchValues.other_fees, (oFAmount) =>
            parseInt(oFAmount, 10)
          );
        }
        newTotal += parseInt(sum(otherFeesAmounts), 10);
      }
      if (newTotal !== newTotalAmount) {
        setNewTotalAmount(parseInt(newTotal, 10));
      }
    }
  }, [watchValues]);

  const handleFormSubmit = (data) => {
    if (!isEmpty(data)) {
      const payload = {};

      if (
        isArray(data.tuition_fees) &&
        isArray(data.tuition_fees_invoice_ids)
      ) {
        const tuitionPayload = map(
          data.tuition_fees,
          (invoiceAmount, index) => ({
            tuition_invoice_id: data.tuition_fees_invoice_ids[index],
            amount: parseInt(invoiceAmount, 10),
          })
        );

        payload.tuition_invoices = filter(tuitionPayload, (e) => e.amount > 0);
      }
      if (
        isArray(data.functional_fees) &&
        isArray(data.functional_fees_invoice_ids)
      ) {
        const fInvoices = map(data.functional_fees, (invoiceAmount, index) => ({
          functional_fees_invoice_id: data.functional_fees_invoice_ids[index],
          amount: parseInt(invoiceAmount, 10),
        }));

        payload.functional_fees_invoices = filter(
          fInvoices,
          (e) => e.amount > 0
        );
      }
      if (isArray(data.other_fees) && isArray(data.other_fees_invoice_ids)) {
        const oInvoices = map(data.other_fees, (amount, index) => ({
          other_fees_invoice_id: data.other_fees_invoice_ids[index],
          amount: parseInt(amount, 10),
        }));

        payload.other_fees_invoices = filter(oInvoices, (e) => e.amount > 0);
      }

      if (!isEmpty(payload)) {
        dispatch(
          paymentReferenceActions.generatePaymentReference(payload, 'bulk')
        );
      }
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit(handleFormSubmit)}>
        <Accordion defaultActiveKey="1" activeKey={activeKey}>
          {!isEmpty(enrollment.tuitionInvoice) && (
            <InvoiceCard
              invoiceTitle="Tuition Fees Invoice"
              invoiceCardId={1}
              invoiceType="tuition_fees"
              activeKey={activeKey}
              register={register}
              errors={errors}
              setActiveKey={setActiveKey}
              invoice={enrollment.tuitionInvoice}
            />
          )}
          {!isEmpty(enrollment.functionalInvoice) && (
            <InvoiceCard
              invoiceTitle="Functional Fees Invoice"
              invoiceCardId={2}
              invoiceType="functional_fees"
              register={register}
              errors={errors}
              activeKey={activeKey}
              setActiveKey={setActiveKey}
              invoice={enrollment.functionalInvoice}
            />
          )}
          {!isEmpty(enrollment.otherFeesInvoice) && (
            <InvoiceCard
              invoiceTitle="Other Fees Invoice"
              invoiceCardId={3}
              invoiceType="other_fees"
              register={register}
              errors={errors}
              activeKey={activeKey}
              setActiveKey={setActiveKey}
              invoice={enrollment.otherFeesInvoice}
            />
          )}
        </Accordion>
        {newTotalAmount > 0 && (
          <>
            <Card.Header className="border-0 fw-bold text-sm py-2 my-2 bg-dark text-white">
              TOTAL AMOUNT TO PAY
              <div className="card-options text-white">{`${newTotalAmount.toLocaleString()} UGX`}</div>
            </Card.Header>

            <Row>
              <Col md={12} className="text-end">
                <SubmitButton
                  text="GENERATE PRN"
                  size="sm"
                  loading={generatingPaymentReference}
                  loadingText="Generating..."
                  variant="info"
                  className="fw-bold text-sm mt-2"
                />
              </Col>
            </Row>
          </>
        )}
      </Form>

      <ReferenceModal />
    </>
  );
}

GeneratePaymentReference.defaultProps = {
  enrollment: {},
};

GeneratePaymentReference.propTypes = {
  enrollment: PropTypes.oneOfType([object]),
};

export default GeneratePaymentReference;
