import PropTypes, { object } from 'prop-types';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { FaUserGraduate } from 'react-icons/fa';
import { InputText } from '../../components/common';
import { formatName } from '../../helpers/dataFormatter';

function GuardianDetail({ authUser }) {
  return (
    <>
      <Card.Header className="text-primary font600 py-3 text-sm bioData-header">
        <FaUserGraduate className="me-1" />
        GUARDIAN DETAILS
      </Card.Header>
      <Card.Body>
        <Row>
          <Col md={6} xs={6}>
            <InputText
              label="FULL NAME"
              defaultValue={formatName(authUser.guardian_name)}
              disabled
              inline
            />
            <InputText
              label="EMAIL"
              defaultValue={authUser.guardian_email}
              disabled
              inline
            />
            <InputText
              label="TELEPHONE PHONE"
              name="guardian_phone"
              defaultValue={authUser.guardian_phone}
              disabled
              inline
            />
          </Col>
          <Col md={6} xs={6}>
            <InputText
              label="RELATIONSHIP"
              defaultValue={authUser.guardian_relationship}
              disabled
              inline
            />
            <InputText
              label="ADDRESS"
              defaultValue={authUser.guardian_address}
              disabled
              inline
            />
          </Col>
        </Row>
      </Card.Body>
    </>
  );
}

GuardianDetail.propTypes = {
  authUser: PropTypes.oneOfType([object]).isRequired,
};

export default GuardianDetail;
