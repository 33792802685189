import { capitalize, isEmpty, upperCase } from 'lodash';
import PropTypes, { object } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { FaEdit, FaUserGraduate } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { InputText, ReloadButton } from '../../components/common';
import { authActions, settingActions } from '../../config/actions';
import ChangeContactModal from './ChangeContactModal';

function BioData({ authUser }) {
  const dispatch = useDispatch();
  const { gettingAuthUser } = useSelector((state) => state.auth);
  const { showModal } = useSelector((state) => state.setting);
  const [bioData, setBioData] = useState({});

  useEffect(() => {
    if (!isEmpty(authUser)) {
      setBioData(authUser);
    }
  }, [authUser]);

  return (
    <>
      <Card.Header className="text-primary font600 py-2 text-sm bioData-header">
        <FaUserGraduate className="me-1" />
        MY PROFILE DETAILS
        <div className="card-options">
          <Button
            variant="outline-info"
            className="me-2"
            onClick={() => dispatch(settingActions.setShowModal(true))}
          >
            <FaEdit className="me-1" /> Edit Contacts
          </Button>
          <ReloadButton
            loading={gettingAuthUser}
            onClick={() => dispatch(authActions.getAuthUser())}
          />
        </div>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col md={6} xs={6}>
            <InputText
              label="SURNAME"
              defaultValue={upperCase(bioData.surname)}
              disabled
              inline
            />
            <InputText
              label="OTHER NAMES"
              defaultValue={capitalize(bioData.other_names)}
              disabled
              inline
            />
            <InputText
              label="EMAIL"
              name="email"
              defaultValue={bioData.email}
              disabled
              inline
            />
            <InputText
              label="TELE PHONE NUMBER"
              name="phone"
              defaultValue={bioData.phone}
              disabled
              inline
            />
            <InputText
              label="GENDER"
              defaultValue={bioData.gender}
              disabled
              inline
            />
            <InputText
              label="DATE OF BIRTH"
              defaultValue={bioData.date_of_birth}
              className="text-uppercase"
              disabled
              inline
            />
          </Col>
          <Col md={6} xs={6}>
            <InputText
              label="RELIGION"
              defaultValue={bioData.religion}
              disabled
              inline
            />
            <InputText
              label="HOME DISTRICT"
              defaultValue={bioData.home_district}
              disabled
              inline
            />
            <InputText
              label="NATIONALITY"
              defaultValue={bioData.nationality}
              disabled
              inline
            />
            <InputText
              label="NATIONAL ID NO."
              defaultValue={bioData.national_id_number}
              disabled
              inline
            />
            <InputText
              label="PASSPORT NO."
              defaultValue={bioData.passport_id_number}
              disabled
              inline
            />
          </Col>
        </Row>
      </Card.Body>
      {showModal && <ChangeContactModal bioData={bioData} />}
    </>
  );
}

BioData.propTypes = {
  authUser: PropTypes.oneOfType([object]).isRequired,
};

export default BioData;
