import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Card, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { FaSignInAlt } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { InputText, SubmitButton } from '../../components/common';

function LoginForm({ onSubmit }) {
  const { register, handleSubmit, errors } = useForm();
  const loginIn = useSelector((state) => state.auth.loginIn);

  return (
    <Card className="bg-white rounded shadow-sm ">
      <Card.Header className="py-3 border-light fw-bold d-block text-center rounded-top">
        LOGIN TO YOUR ACCOUNT
      </Card.Header>
      <Card.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <InputText
            name="username"
            label="Student Number or Reg. Number"
            requiredField
            register={register({
              required: 'Your Student Number or Reg. Number field is required',
              minLength: {
                value: 7,
                message: 'Student/Reg. No is too short',
              },
              maxLength: { value: 15, message: 'Student/Reg. No  is too long' },
            })}
            error={get(errors, 'username.message')}
          />
          <InputText
            type="password"
            name="password"
            label="Password"
            requiredField
            register={register({
              required: 'Your Password is required',
              minLength: { value: 6, message: 'Password is too short' },
              maxLength: { value: 16, message: 'Password is too long' },
            })}
            error={get(errors, 'password.message')}
          />

          <SubmitButton
            className="text-uppercase text-white text-sm my-3 w-100 fw-normal"
            text="SIGN IN"
            size="sm"
            loading={loginIn}
            iconBefore={<FaSignInAlt className="me-1" />}
            loadingText="Signing in..."
          />
        </Form>
      </Card.Body>
    </Card>
  );
}

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default LoginForm;
