import { Col, Row } from 'antd';
import {
  find,
  findIndex,
  forEach,
  includes,
  isArray,
  isEmpty,
  kebabCase,
  toLower,
  toUpper,
} from 'lodash';
import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { FaCalendarDay, FaUserGraduate, FaUserTie } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { DataNotFound } from '../../components/common';
import { appActions, settingActions } from '../../config/actions';
// import MenuList from '../../config/routes/MenuList';
import ServiceSideMenu from './ServiceSideMenu';

function Services() {
  const [currentTab, setCurrentTab] = useState('service-dashboard');
  const dispatch = useDispatch();
  const { metadata } = useSelector((state) => state.app);
  const { activeProgramme } = useSelector((state) => state.auth);
  const { appNavigation, selectedMenu } = useSelector((state) => state.setting);
  const [serviceList, setServiceList] = useState([]);
  useEffect(() => {
    if (isEmpty(metadata)) {
      dispatch(appActions.getMetadata());
    }
  }, []);

  useEffect(() => {
    if (metadata) {
      const services = [];

      if (isArray(metadata)) {
        const findCOP = find(metadata, (e) =>
          includes(toUpper(e.metadata_name), 'CHANGE OF PROGRAMME')
        );

        if (findCOP && findCOP.metadataValues) {
          forEach(findCOP.metadataValues, (e) => {
            if (
              includes(toUpper(e.metadata_value), 'CHANGE OF PROGRAMME') &&
              !includes(toUpper(e.metadata_value), 'ADMINISTRATIVE')
            ) {
              services.push({
                id: kebabCase(toLower(e.metadata_value)),
                icon: <FaUserGraduate size={32} />,
                title: 'CHANGE OF PROGRAMME',
                action: kebabCase(toLower(e.metadata_value)),
              });
            } else if (
              includes(toUpper(e.metadata_value), 'STUDY TIME') &&
              !includes(toUpper(e.metadata_value), 'ADMINISTRATIVE')
            ) {
              services.push({
                id: kebabCase(toLower(e.metadata_value)),
                icon: <FaCalendarDay size={32} />,
                title: 'CHANGE OF STUDY TIME',
                action: kebabCase(toLower(e.metadata_value)),
              });
            } else if (
              includes(toUpper(e.metadata_value), 'SUBJECT COMB') &&
              !includes(toUpper(e.metadata_value), 'ADMINISTRATIVE')
            ) {
              services.push({
                id: kebabCase(toLower(e.metadata_value)),
                icon: <FaCalendarDay size={32} />,
                title: 'CHANGE OF SUBJECT COMBINATION',
                action: kebabCase(toLower(e.metadata_value)),
              });
            }
          });
        }

        const registrationTypes = find(metadata, (e) =>
          includes(toUpper(e.metadata_name), 'REGISTRATION TYPES')
        );

        if (
          findIndex(
            registrationTypes?.metadataValues,
            (e) =>
              includes(toUpper(e.metadata_name), 'PROVISIONAL') ||
              includes(toUpper(e.metadata_name), 'ADMINISTRATIVE')
          )
        ) {
          services.push({
            id: 'administrative-registration',
            icon: <FaUserTie size={32} />,
            title: 'ADMINISTRATIVE REGISTRATION',
            action: 'administrative-registration',
          });
        }
      }
      setServiceList(services);
    }
  }, [metadata]);

  useEffect(() => {
    const { key } = selectedMenu;
    const activeServiceTab = currentTab.split('-').join(' ');

    if (key === 'apply-for-services') {
      dispatch(
        settingActions.setNavigation({
          ...appNavigation,
          menuLevel_1: 'services',
          menuLevel_2: selectedMenu?.label,
          menuLevel_3: activeServiceTab,
        })
      );
    }
  }, []);

  const navigateUser = (route = 'service-dashboard') => {
    dispatch(
      settingActions.setNavigation({
        ...appNavigation,
        menuLevel_1: 'services',
        menuLevel_2: 'apply for services',
        menuLevel_3: route,
      })
    );
  };

  const handleClick = (route, action) => {
    setCurrentTab(action);
    navigateUser(route);
  };
  return (
    <>
      {isEmpty(activeProgramme) ? (
        <DataNotFound message="Services are only for Continuing students. You have no active programme" />
      ) : (
        <>
          {(currentTab !== 'service-dashboard' && (
            <ServiceSideMenu
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
              navigateUser={navigateUser}
            />
          )) || (
            <Row gutter={10} className="row-deck">
              {serviceList.map((service) => (
                <Col md={6} key={service.id}>
                  <Card
                    body
                    className="text-center shadow-0 border mb-2 text-primary p-2 service-card"
                    onClick={() => handleClick(service.title, service.action)}
                    disabled={service.disabled}
                  >
                    {service.icon}
                    <div className="font600 text-sm text-uppercase my-4">
                      {service.title}
                    </div>
                  </Card>
                </Col>
              ))}
            </Row>
          )}
        </>
      )}
    </>
  );
}

export default Services;
