import { find, isEmpty } from 'lodash';
import PropTypes, { object } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { FaReply, FaSave } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import {
  DataNotFound,
  InputText,
  SubmitButton,
} from '../../../components/common';
import { serviceActions } from '../../../config/actions';

function PreviewChange({
  data,
  setShowPreview,
  isEditing,
  dataToEdit,
  currentTab,
}) {
  const [selectedProgramme, setSelectedProgramme] = useState({});
  const { activeProgramme } = useSelector((state) => state.auth);
  const { submittingApplication, updatingApplication, programmes } =
    useSelector((state) => state.service);
  const dispatch = useDispatch();

  useEffect(() => {
    if (data && programmes) {
      const findProgramme = find(
        programmes,
        (programme) =>
          parseInt(programme.id, 10) ===
          parseInt(data?.new_programme_id?.value, 10)
      );

      if (findProgramme) {
        const findCampus = find(
          findProgramme.campuses,
          (e) => parseInt(e.campus_id, 10) === parseInt(data.new_campus_id, 10)
        );
        const programmeType = find(
          findProgramme.programme_types,
          (e) =>
            parseInt(e.programme_type_id, 10) ===
            parseInt(data.new_programme_type_id, 10)
        );
        const studyYear = find(
          findProgramme.programme_study_years,
          (e) =>
            parseInt(e.context_id, 10) === parseInt(data.new_study_year_id, 10)
        );
        setSelectedProgramme((state) => ({
          ...state,
          ...data,
          programme_title: findProgramme?.programme_title,
          campus: findCampus?.campus || 'N/A',
          programme_type: programmeType?.programme_type || 'N/A',
          study_year: studyYear?.programme_study_year || 'N/A',
        }));
      } else setShowPreview(false);
    }

    return () => {
      setShowPreview(false);
    };
  }, [programmes]);

  const submitFormHandler = () => {
    if (!isEmpty(data) && !submittingApplication) {
      dispatch(
        serviceActions[isEditing ? 'updateApplication' : 'submitApplication'](
          {
            ...data,
            new_programme_type_id:
              selectedProgramme?.new_programme_type_id || data.programme_type,
            service: currentTab,
          },
          'change-of-programme',
          activeProgramme?.id,
          dataToEdit?.id
        )
      );
    }
  };

  return (
    <>
      {isEmpty(selectedProgramme) ? (
        <DataNotFound message="No New Programme Selected, Please Go back and Select" />
      ) : (
        <>
          <div className="fs-3 fw600 text-center mb-4 text-uppercase">
            CONFIRM YOUR CHANGE OF PROGRAMME DATA AND CLICK{' '}
            {isEditing ? 'Update Application' : 'Submit Application'} BUTTON
          </div>

          <Table responsive bordered size="sm" className="mb-4">
            <tbody>
              <tr>
                <td className="fw-bold text-sm" colSpan={2}>
                  CURRENT PROGRAMME
                </td>
              </tr>
              <tr>
                <td width={200}>Title</td>
                <td>{activeProgramme?.programme_title}</td>
              </tr>
              <tr>
                <td>Study Time</td>
                <td>{activeProgramme?.programme_type}</td>
              </tr>
              <tr>
                <td>Study Year</td>
                <td>{activeProgramme?.current_study_year}</td>
              </tr>
              <tr>
                <td>Campus</td>
                <td>{activeProgramme?.campus}</td>
              </tr>
              <tr>
                <td>Entry Academic Year</td>
                <td>{activeProgramme?.entry_academic_year}</td>
              </tr>
            </tbody>
          </Table>

          <InputText
            label="NEW PROGRAMME"
            inline
            disabled
            defaultValue={data?.new_programme_id?.label}
          />
          {data?.new_subject_comb_id && (
            <InputText
              label="NEW SUBJECT COMBINATION"
              inline
              disabled
              defaultValue={data?.new_subject_comb_id?.label}
            />
          )}
          <InputText
            label="NEW STUDY YEAR"
            inline
            disabled
            defaultValue={selectedProgramme?.study_year}
          />
          <InputText
            label="NEW STUDY TIME"
            inline
            disabled
            defaultValue={selectedProgramme?.programme_type}
          />
          <InputText
            label="NEW CAMPUS"
            inline
            disabled
            defaultValue={selectedProgramme?.campus}
          />
          <InputText
            label="ENTRY ACADEMIC YEAR"
            inline
            disabled
            defaultValue={data?.academic_year_id?.label}
          />
          <InputText
            label="REASON FOR CHANGE"
            inline
            disabled
            type="textarea"
            defaultValue={selectedProgramme?.reason}
          />

          <div className="text-center d-block">
            <SubmitButton
              size="sm"
              text={isEditing ? 'Update Application' : 'Submit Application'}
              loadingText={
                isEditing ? 'Updating...' : 'Submitting Application...'
              }
              className="float-end text-uppercase mx-2"
              loading={submittingApplication || updatingApplication}
              onClick={submitFormHandler}
              iconBefore={<FaSave className="me-1" />}
            />
            <SubmitButton
              size="sm"
              text="Edit Application"
              className="float-end text-uppercase"
              variant="danger"
              iconBefore={<FaReply className="me-1" />}
              onClick={() => setShowPreview(false)}
            />
          </div>
        </>
      )}
    </>
  );
}

PreviewChange.defaultProps = {
  data: {},
  dataToEdit: {},
  isEditing: false,
};
PreviewChange.propTypes = {
  setShowPreview: PropTypes.func.isRequired,
  currentTab: PropTypes.string.isRequired,
  isEditing: PropTypes.bool,
  data: PropTypes.oneOfType([object]),
  dataToEdit: PropTypes.oneOfType([object]),
};

export default PreviewChange;
