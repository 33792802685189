import PropTypes, { object } from 'prop-types';
import React, { useRef } from 'react';
import { ModalBody, ModalFooter } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { FaPrint } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import { CustomModal, SubmitButton } from '../../../components/common';
import ExamPermit from './ExamPermit';
import ProofOfRegistration from './ProofOfRegistration';

function RegistrationCard({ selectedRegistration }) {
  const componentRef = useRef();
  const { registrationCardType } = useSelector((state) => state.setting);

  const printHandler = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <CustomModal
      title={
        registrationCardType === 'examPermit'
          ? 'EXAMINATION PERMIT PREVIEW'
          : 'PROOF OF REGISTRATION'
      }
      scrollable
      centered
      size="lg"
    >
      <Helmet>
        <title>
          {registrationCardType === 'examPermit'
            ? 'EXAMINATION PERMIT'
            : 'PROOF OF REGISTRATION'}
        </title>
      </Helmet>
      <ModalBody className="text-sms" ref={componentRef}>
        {registrationCardType === 'examPermit' ? (
          <ExamPermit selectedRegistration={selectedRegistration} />
        ) : (
          <ProofOfRegistration selectedRegistration={selectedRegistration} />
        )}
      </ModalBody>
      <ModalFooter className="py-2 bg-light">
        <SubmitButton
          type="button"
          text="PRINT"
          iconBefore={<FaPrint className="me-2" />}
          onClick={printHandler}
        />
      </ModalFooter>
    </CustomModal>
  );
}

RegistrationCard.propTypes = {
  selectedRegistration: PropTypes.oneOfType([object]).isRequired,
};

export default RegistrationCard;
